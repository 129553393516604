import { Application } from "@hotwired/stimulus"
const application = Application.start()

import Chart from 'stimulus-chartjs'
application.register('chart', Chart)

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application
console.log('application.js')
